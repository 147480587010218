import * as React from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import StarIcon from '@mui/icons-material/Star';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { getOrCreateUserData } from "../api/user";

export default function Profile() {
    const [email, setEmail] = React.useState<string>("");
    const [userType, setUserType] = React.useState<string>("Free");
    const [answers, setAnswers] = React.useState<number>(0);
    const navigate = useNavigate();

    React.useEffect(() => {
        const auth = getAuth();
        auth.onAuthStateChanged((user) => {
            if (user) {
                setEmail(user.email || user.displayName || "");
                getOrCreateUserData(user.uid).then((userDoc) => {
                    setUserType(userDoc.userType);
                    setAnswers(userDoc.answers);
                });
            } else {
                navigate("/login");
            }
        });
    }, [navigate]);

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper
                        variant="outlined"
                        sx={{
                            p: 3,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 3,
                        }}
                    >
                        <Typography variant="h5" sx={{ mb: 2 }}>
                            Información del Usuario
                        </Typography>

                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <PersonRoundedIcon color="primary" sx={{ fontSize: 40 }} />
                            <Box>
                                <Typography variant="subtitle2" color="text.secondary">
                                    Email
                                </Typography>
                                <Typography variant="h6">
                                    {email}
                                </Typography>
                            </Box>
                        </Box>

                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <StarIcon color="primary" sx={{ fontSize: 40 }} />
                            <Box>
                                <Typography variant="subtitle2" color="text.secondary">
                                    Tipo de Cuenta
                                </Typography>
                                <Typography variant="h6">
                                    {userType}
                                </Typography>
                                {userType === "Free" && (
                                    <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                                        Actualiza a Premium para tener algoritmos ilimitados
                                    </Typography>
                                )}
                            </Box>
                        </Box>

                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <AssignmentIcon color="primary" sx={{ fontSize: 40 }} />
                            <Box>
                                <Typography variant="subtitle2" color="text.secondary">
                                    Algoritmos Completados
                                </Typography>
                                <Typography variant="h6">
                                    {answers}
                                </Typography>
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
} 