import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { getAnswers } from "../api/answers";
import { Answer } from "../types/dbTypes";

interface Props {
  uid: string;
  algorithmAmount: number;
}

export default function RecentAlgorithmTable({ uid, algorithmAmount }: Props) {
  const answerLoadAmount = 50;
  const [answers, setAnswers] = React.useState<Answer[] | null>(null);

  React.useEffect(() => {
    getAnswers(uid, answerLoadAmount).then((ans) => setAnswers(ans));
  }, [uid, algorithmAmount]);

  if (!answers) return null;

  const formatDate = (timestamp: any) => {
    return timestamp.toString();
  };

  return (
    <TableContainer component={Paper} variant="outlined">
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="subtitle2" fontWeight={600}>Fecha</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle2" fontWeight={600}>Algoritmo</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle2" fontWeight={600}>Resultado</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {answers.map((answer, index) => (
            <TableRow
              key={index}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                '&:hover': {
                  bgcolor: 'action.hover',
                },
              }}
            >
              <TableCell>
                <Typography variant="body2">
                  {formatDate(answer.timestamp)}
                </Typography>
              </TableCell>
              <TableCell>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Typography variant="body2">
                    {answer.algorithm}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell>
                <Typography
                  variant="body2"
                  sx={{
                    maxWidth: 400,
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                  }}
                >
                  {answer.result}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
