import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import MuiCard from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import { GoogleAuthProvider, getAuth, createUserWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import * as React from 'react';
import { Link as RouterLink, useNavigate } from "react-router-dom";
import InveraLogo from "../components/InveraLogo";
import { GoogleIcon } from '../components/CustomIcons';

const Card = styled(MuiCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  width: '100%',
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  margin: 'auto',
  [theme.breakpoints.up('sm')]: {
    maxWidth: '450px',
  },
  boxShadow:
    'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
  ...theme.applyStyles('dark', {
    boxShadow:
      'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px',
  }),
}));

const SignUpContainer = styled(Stack)(({ theme }) => ({
  minHeight: '100vh',
  padding: theme.spacing(2),
  position: 'relative',
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(4),
  },
  '&::before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    zIndex: -1,
    inset: 0,
    backgroundImage:
      'radial-gradient(ellipse at 50% 50%, hsl(150, 100%, 97%), hsl(0, 0%, 100%))',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    ...theme.applyStyles('dark', {
      backgroundImage:
        'radial-gradient(ellipse at 50% 50%, hsla(150, 30%, 16%, 0.5), hsl(220, 30%, 5%))',
    }),
  },
}));

function SignUp() {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");

  const [nameError, setNameError] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [passwordError, setPasswordError] = React.useState("");

  const navigate = useNavigate();
  React.useEffect(() => {
    getAuth().onAuthStateChanged((user) => {
      if (user) navigate("/");
    });
  });

  const validateInputs = () => {
    let isValid = true;

    if (!name || name.length < 1) {
      setNameError("Por favor ingrese su nombre");
      isValid = false;
    } else {
      setNameError("");
    }

    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Por favor ingrese un email válido");
      isValid = false;
    } else {
      setEmailError("");
    }

    if (!password || password.length < 6) {
      setPasswordError("La contraseña debe tener al menos 6 caracteres");
      isValid = false;
    } else if (password !== confirmPassword) {
      setPasswordError("Las contraseñas no coinciden");
      isValid = false;
    } else {
      setPasswordError("");
    }

    return isValid;
  };

  const register = () => {
    if (!validateInputs()) return;

    const auth = getAuth();
    createUserWithEmailAndPassword(auth, email, password).catch((e) => {
      if (e.code === "auth/email-already-in-use") {
        setEmailError("El email ya está en uso");
      } else {
        setEmailError(`Error desconocido: ${e.code}`);
      }
    });
  };

  const googleSignUp = () => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider).catch((e) => {
      setEmailError(`Error desconocido: ${e.code}`);
    });
  };

  return (
    <>
      <CssBaseline enableColorScheme />
      <SignUpContainer direction="column" justifyContent="space-between">
        <Card variant="outlined">
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 1 }}>
            <InveraLogo width='120px' />
          </Box>
          <Typography
            component="h1"
            variant="h4"
            sx={{
              width: '100%',
              fontSize: 'clamp(2rem, 10vw, 2.15rem)',
              fontWeight: 500,
              mb: 3
            }}
          >
            Registrarse
          </Typography>
          <Box
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              gap: 2,
            }}
          >
            <FormControl>
              <FormLabel htmlFor="name">Nombre completo</FormLabel>
              <TextField
                error={nameError.length > 0}
                helperText={nameError}
                id="name"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Juan Pérez"
                autoComplete="name"
                autoFocus
                required
                fullWidth
                variant="outlined"
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="email">Email</FormLabel>
              <TextField
                error={emailError.length > 0}
                helperText={emailError}
                id="email"
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="tu@email.com"
                autoComplete="email"
                required
                fullWidth
                variant="outlined"
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="password">Contraseña</FormLabel>
              <TextField
                error={passwordError.length > 0}
                helperText={passwordError}
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="••••••"
                type="password"
                id="password"
                autoComplete="new-password"
                required
                fullWidth
                variant="outlined"
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="confirmPassword">Confirmar contraseña</FormLabel>
              <TextField
                error={passwordError.length > 0}
                name="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="••••••"
                type="password"
                id="confirmPassword"
                autoComplete="new-password"
                required
                fullWidth
                variant="outlined"
              />
            </FormControl>
            <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'center' }}>
              Al registrarse estás aceptando los{' '}
              <Link
                component={RouterLink}
                to="/terms"
                target="_blank"
              >
                términos y condiciones
              </Link>
            </Typography>
            <Button
              fullWidth
              variant="contained"
              onClick={register}
              sx={{ py: 1.5 }}
            >
              Registrarse
            </Button>
          </Box>
          <Divider sx={{ my: 2 }}>o</Divider>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Button
              fullWidth
              variant="outlined"
              onClick={googleSignUp}
              startIcon={<GoogleIcon />}
              sx={{
                py: 1.5,
                borderColor: 'divider',
                '&:hover': {
                  borderColor: 'primary.main',
                  bgcolor: 'action.hover',
                },
              }}
            >
              Registrarse con Google
            </Button>
            <Typography sx={{ textAlign: 'center' }}>
              ¿Ya tienes una cuenta?{' '}
              <Link
                component={RouterLink}
                to="/login"
                variant="body2"
              >
                Inicia sesión
              </Link>
            </Typography>
          </Box>
        </Card>
        <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 4 }}>
          Al registrarte, estás aceptando los términos y condiciones de la aplicación
        </Typography>
      </SignUpContainer>
    </>
  );
}

export default SignUp;
