import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import Typography from '@mui/material/Typography';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { useNavigate } from 'react-router-dom';
import { getDescriptions } from "../api/algorithm-description";
import { AlgorithmDescription } from "../types/dbTypes";

const AvailableAlgorithmList: React.FC = () => {
  const [algorithms, setAlgorithms] = React.useState<
    AlgorithmDescription[] | null
  >(null);

  React.useEffect(() => {
    if (algorithms == null) {
      getDescriptions().then((res) => setAlgorithms(res));
    }
  });

  const navigate = useNavigate();

  return (
    <Grid container spacing={3}>
      {algorithms &&
        algorithms.map((algorithm) => (
          <Grid item xs={12} md={6} key={algorithm.id}>
            <Card
              variant="outlined"
              sx={{
                height: '100%',
                transition: 'transform 0.2s, box-shadow 0.2s',
                '&:hover': {
                  transform: 'translateY(-4px)',
                  boxShadow: (theme) => `0 4px 20px ${theme.palette.action.hover}`,
                },
              }}
            >
              <CardActionArea
                onClick={() => navigate(`/assistant/${algorithm.id}`)}
                sx={{ height: '100%' }}
              >
                <CardContent sx={{ height: '100%', p: 3 }}>
                  <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2, mb: 2 }}>
                    <PlayArrowIcon
                      sx={{
                        fontSize: 40,
                        color: 'primary.main',
                        bgcolor: 'action.hover',
                        borderRadius: '50%',
                        p: 1,
                      }}
                    />
                    <Box sx={{ flex: 1 }}>
                      <Typography variant="h6" gutterBottom>
                        {algorithm.title}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
    </Grid>
  );
};

export default AvailableAlgorithmList;
