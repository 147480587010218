import { List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import React from "react";

interface Props {
  bulletPoints: string[];
}

const AlgorithmBulletPoints: React.FC<Props> = (props) => {
  return (
    <List>
      {props.bulletPoints.map((bullet, index) => (
        <ListItem key={index}>
          <ListItemIcon>
            <Typography variant="h6" color="text.primary">
              {index + 1}.
            </Typography>
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1" color="text.primary">
                {bullet}
              </Typography>
            }
          />
        </ListItem>
      ))}
    </List>
  );
}

export default AlgorithmBulletPoints;