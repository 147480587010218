import LockIcon from '@mui/icons-material/Lock';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { getAuth } from "firebase/auth";
import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { getOrCreateUserData } from "../api/user";
import AvailableAlgorithmList from '../components/AvailableAlgorithmsList';
import { MAX_FREE_ALGORITHMS } from '../globals';


export default function NewAlgorithm() {
    const [userType, setUserType] = React.useState<string>("Free");
    const [answers, setAnswers] = React.useState<number>(0);
    const navigate = useNavigate();

    React.useEffect(() => {
        const auth = getAuth();
        auth.onAuthStateChanged((user) => {
            if (user) {
                getOrCreateUserData(user.uid).then((userDoc) => {
                    setUserType(userDoc.userType);
                    setAnswers(userDoc.answers);
                });
            } else {
                navigate("/login");
            }
        });
    }, [navigate]);

    const isPremiumOrFirstUse = userType === "Premium" || answers < MAX_FREE_ALGORITHMS;

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            {isPremiumOrFirstUse ? (
                <Paper
                    variant="outlined"
                    sx={{
                        p: 3,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                    }}
                >
                    <Typography variant="h5" align="center" sx={{ mb: 2 }}>
                        Selecciona un Algoritmo
                    </Typography>
                    <AvailableAlgorithmList />
                </Paper>
            ) : (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper
                            variant="outlined"
                            sx={{
                                p: 4,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                gap: 3,
                                textAlign: 'center',
                            }}
                        >
                            <LockIcon sx={{ fontSize: 60, color: 'primary.main' }} />
                            <Typography variant="h5">
                                Acceso Premium Requerido
                            </Typography>
                            <Typography variant="body1" color="text.secondary" sx={{ maxWidth: 600 }}>
                                Para continuar utilizando los algoritmos, necesitas una suscripción premium.
                                Obtén acceso ilimitado a todos nuestros algoritmos y características.
                            </Typography>
                            <Button
                                variant="contained"
                                size="large"
                                onClick={() => navigate('/checkout')}
                                sx={{
                                    mt: 2,
                                    px: 4,
                                    py: 1.5,
                                }}
                            >
                                Obtener Premium
                            </Button>
                        </Paper>
                    </Grid>
                </Grid>
            )}
        </Container>
    );
} 