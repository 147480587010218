import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles';
import { getDesignTokens } from './theme/themePrimitives';
import store from './redux/store';
import AppTheme from './theme/AppTheme';
import CssBaseline from '@mui/material/CssBaseline';

interface ProvidersProps {
    children: React.ReactNode;
}

export function Providers({ children }: ProvidersProps) {
    return (
        <Provider store={store}>
            <CssVarsProvider theme={getDesignTokens('light')} defaultMode="system">
                <AppTheme>
                    <CssBaseline />
                    <BrowserRouter>
                        {children}
                    </BrowserRouter>
                </AppTheme>
            </CssVarsProvider>
        </Provider>
    );
} 