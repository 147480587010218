import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import InveraLogo from './InveraLogo';

interface HeaderProps {
    username: string;
}

export default function Header({ username }: HeaderProps) {
    const theme = useTheme();
    return (
        <Box
            sx={{
                width: '100%',
                paddingTop: 4,
                paddingBottom: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                background: 'linear-gradient(90deg, #53AC59 24%, #3B8952 72%)', // Updated gradient colors
            }}
        >
            <InveraLogo width='150px' />
            <Typography
                sx={{
                    color: theme.palette.primary.contrastText,
                    pl: 3,
                    pr: 3,
                    pt: 2,
                    pb: 2
                }}
                component="h1"
                variant="h5"
            >
                Bienvenido/a, {username}
            </Typography>
        </Box>
    );
}