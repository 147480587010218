import React from "react";
import ResultComponent from "../components/Result";
import {
  Stack,
  Box,
  Typography,
  LinearProgress,
  CircularProgress,
  Container,
  Paper,
  alpha,
  Button,
} from "@mui/material";
import { useAppSelector, useAppDispatch } from "../hooks";
import { getAuth, User as firebaseUser } from "firebase/auth";
import UndoIcon from '@mui/icons-material/Undo';

import { loadTree, navigateToNode, undoLastNode } from "../redux/assistant/assistantSlice";
import Question from "../components/Question";
import { Node } from "../types/assistantTypes";
import { Algorithm } from "../types/dbTypes";

import { useNavigate, useParams } from "react-router-dom";
import { getAlgorithm } from "../api/algorithm";
import { submitAnswer } from "../api/answers";
import store from "../redux/store";

const defaultNode: Node = {
  id: -1,
  isEnd: true,
  answers: [],
};

function Assistant(): JSX.Element {
  const { algorithm } = useParams();
  const [user, setUser] = React.useState<firebaseUser | null>(null);
  const [algorithmObj, setAlgorithmObj] = React.useState<Algorithm | null>(
    null
  );

  const navigate = useNavigate();
  React.useEffect(() => {
    getAuth().onAuthStateChanged((user) => {
      if (user) setUser(user);
      else navigate("/login");
    });
  });

  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.assistant);

  React.useEffect(() => {
    if (algorithm) {
      getAlgorithm(algorithm).then((res) => setAlgorithmObj(res));
    } else {
      navigate("/");
    }
  }, [algorithm, navigate]);

  React.useEffect(() => {
    if (algorithmObj) {
      dispatch(loadTree(algorithmObj.algorithm));
    }
  }, [algorithmObj, dispatch]);

  const handleAnswer = (answer: number): void => {
    dispatch(navigateToNode(answer));
    const state = store.getState().assistant; // Get new state
    const currentNode =
      state.questionTree.find((e) => e.id === state.currentNode) || defaultNode;

    if (state.maxStepsLeft === 0 && algorithmObj && user) {
      // If finished already
      const uid = user.uid;

      if (currentNode.result == null) {
        console.error("End point does not have a result!");
        submitAnswer(uid, algorithmObj.title, "Default answer");
      } else {
        submitAnswer(uid, algorithmObj.title, currentNode.result.title);
      }
    }
  };

  const handleUndo = () => {
    dispatch(undoLastNode());
  };

  const currentNode =
    state.questionTree.find((e) => e.id === state.currentNode) || defaultNode;

  if (algorithmObj == null) {
    return (
      <Container>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{ minHeight: '100vh' }}
        >
          <CircularProgress size={48} />
        </Stack>
      </Container>
    );
  }

  const progress = ((state.maxDepth - state.maxStepsLeft) * 100) / state.maxDepth;

  return (
    <Box
      sx={{
        minHeight: '100vh',
        bgcolor: (theme) => alpha(theme.palette.background.default, 0.98),
        position: 'relative',
      }}
    >
      <Paper
        elevation={0}
        sx={{
          position: 'sticky',
          top: 0,
          left: 0,
          right: 0,
          zIndex: 10,
          bgcolor: (theme) => alpha(theme.palette.primary.main, 0.04),
          borderBottom: 1,
          borderColor: 'divider',
          minHeight: 96
        }}
      >
        <Box sx={{
          position: 'relative',
          width: '100%',
          height: '100%'
        }}>
          <Button
            onClick={handleUndo}
            disabled={state.nodeHistory.length === 0}
            startIcon={<UndoIcon />}
            sx={{
              position: 'absolute',
              top: 16,
              left: { xs: 'calc(16px + 48px)', sm: 'calc(24px + 48px)' },
              opacity: state.nodeHistory.length === 0 ? 0.5 : 1,
              color: 'text.secondary',
              height: 40,
              minWidth: 40,
              border: 1,
              borderColor: 'divider',
              borderRadius: 1,
              textTransform: 'none',
              fontSize: '0.875rem',
              px: 2,
              '&:hover': {
                bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
                borderColor: 'primary.main',
              },
              '& .MuiButton-startIcon': {
                mr: 1,
                '& svg': {
                  fontSize: '1.5rem'
                }
              }
            }}
          >
            Deshacer última respuesta
          </Button>
          <Box sx={{ pt: 9, px: 2, pb: 2 }}>
            <Typography
              variant="h6"
              sx={{
                textAlign: 'center',
                fontWeight: 600,
                color: 'text.secondary',
                fontSize: '0.875rem',
                mb: 1
              }}
            >
              {algorithmObj.title}
            </Typography>
            <Box sx={{ px: { xs: 2, sm: 4, md: 8 } }}>
              <LinearProgress
                variant="determinate"
                value={progress}
                sx={{
                  height: 8,
                  borderRadius: 4,
                  bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
                  '& .MuiLinearProgress-bar': {
                    borderRadius: 4,
                    backgroundImage: (theme) => `linear-gradient(90deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
                  },
                }}
              />
              <Typography
                variant="caption"
                sx={{
                  display: 'block',
                  textAlign: 'center',
                  mt: 0.5,
                  color: 'text.secondary'
                }}
              >
                {Math.round(progress)}% completado
              </Typography>
            </Box>
          </Box>
        </Box>
      </Paper>

      <Container maxWidth="lg" sx={{ py: 2 }}>
        {!currentNode.isEnd ? (
          <Question question={currentNode} onAnswer={handleAnswer} />
        ) : (
          <ResultComponent result={currentNode.result} />
        )}
      </Container>
    </Box>
  );
}

export default Assistant;
