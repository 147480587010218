import * as React from 'react';
import { Routes, Route } from "react-router-dom";

// Layouts
import MainLayout from './layouts/MainLayout';

// Pages
import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Recover from "./pages/Recover";
import Terms from "./pages/Terms";
import Profile from "./pages/Profile";
import History from "./pages/History";
import NewAlgorithm from "./pages/NewAlgorithm";
import Assistant from "./pages/Assistant";
import MPCheckout from "./components/Checkout";

function App() {
  return (
    <Routes>
      {/* Auth routes */}
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/recover" element={<Recover />} />
      <Route path="/terms" element={<Terms />} />

      {/* App routes */}
      <Route element={<MainLayout />}>
        <Route path="/" element={<Home />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/history" element={<History />} />
        <Route path="/new" element={<NewAlgorithm />} />
        <Route path="/assistant/:algorithm" element={<Assistant />} />
        <Route path="/checkout" element={<MPCheckout />} />
      </Route>
    </Routes>
  );
}

export default App;
