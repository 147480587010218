import {
  Stack,
  Button,
  Typography,
  Collapse,
  List,
  ListItem,
  Paper,
  Box,
  alpha
} from "@mui/material";
import { Node } from "../types/assistantTypes";
import React from "react";
import AlgorithmBulletPoints from "./AlgorithmBulletPoints";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

interface Props {
  question: Node;
  onAnswer: (answer: number) => void;
}

const Question: React.FC<Props> = (props) => {
  const [selectedAnswer, setSelectedAnswer] = React.useState<number | null>(null);
  const [showDefinitions, setShowDefinitions] = React.useState(false);

  const handleAnswerSelect = (answerId: number) => {
    setSelectedAnswer(answerId);
  };

  const handleSubmit = () => {
    if (selectedAnswer !== null) {
      props.onAnswer(selectedAnswer);
      setSelectedAnswer(null);
      setShowDefinitions(false);
    }
  };

  return (
    <Stack
      spacing={4}
      sx={{
        maxWidth: 800,
        mx: 'auto',
        width: '100%',
        py: 4,
        px: { xs: 2, sm: 4 }
      }}
    >
      <Box>
        <Typography
          variant="h5"
          gutterBottom
          sx={{
            fontWeight: 700,
            textAlign: 'center',
            mb: 3
          }}
        >
          {props.question.question}
        </Typography>

        {props.question.bulletPoints && (
          <Paper
            variant="outlined"
            sx={{
              p: 2,
              mb: 3,
              bgcolor: (theme) => alpha(theme.palette.background.paper, 0.6)
            }}
          >
            <AlgorithmBulletPoints bulletPoints={props.question.bulletPoints} />
          </Paper>
        )}

        {props.question.description && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
            <Button
              onClick={() => setShowDefinitions(!showDefinitions)}
              color={showDefinitions ? 'primary' : 'inherit'}
              startIcon={<InfoOutlinedIcon />}
              sx={{
                borderRadius: 2,
                px: 2,
                py: 1,
                border: 1,
                borderColor: showDefinitions ? 'primary.main' : 'divider',
                bgcolor: (theme) => showDefinitions ? alpha(theme.palette.primary.main, 0.08) : 'transparent',
                color: (theme) => showDefinitions ? theme.palette.primary.main : theme.palette.text.primary,
                '&:hover': {
                  bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
                  borderColor: (theme) => showDefinitions ? theme.palette.primary.main : theme.palette.primary.light,
                }
              }}
            >
              Definiciones
            </Button>
          </Box>
        )}

        <Collapse in={showDefinitions}>
          <Paper
            variant="outlined"
            sx={{
              p: 2,
              mb: 3,
              bgcolor: (theme) => alpha(theme.palette.background.paper, 0.6)
            }}
          >
            <List>
              {props.question.description?.split("*(").map((paragraph, idx) => (
                <ListItem key={`paragraph-${idx}`}>
                  <Typography variant="body2" color="text.secondary">
                    {paragraph}
                  </Typography>
                </ListItem>
              ))}
            </List>
          </Paper>
        </Collapse>
      </Box>

      <Stack spacing={2}>
        {props.question.answers.map((answer) => (
          <Button
            key={answer.toNode}
            variant={selectedAnswer === answer.toNode ? "contained" : "outlined"}
            onClick={() => handleAnswerSelect(answer.toNode)}
            sx={{
              py: 2,
              px: 3,
              justifyContent: 'flex-start',
              textAlign: 'left',
              borderWidth: 2,
              position: 'relative',
              '&:hover': {
                borderWidth: 2,
              },
              ...(selectedAnswer === answer.toNode && {
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  inset: -3,
                  border: (theme) => `3px solid ${theme.palette.primary.main}`,
                  borderRadius: 'inherit',
                  opacity: 0.5,
                }
              })
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              <Typography
                variant="body1"
                sx={{
                  flex: 1,
                  fontWeight: selectedAnswer === answer.toNode ? 600 : 400
                }}
              >
                {answer.answer}
              </Typography>
              {selectedAnswer === answer.toNode && (
                <CheckCircleIcon sx={{ ml: 1 }} />
              )}
            </Box>
          </Button>
        ))}
      </Stack>

      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          variant="contained"
          size="large"
          disabled={selectedAnswer === null}
          onClick={handleSubmit}
          sx={{
            minWidth: 200,
            py: 1.5,
            fontSize: '1.1rem',
            fontWeight: 600,
            borderRadius: 3,
            bgcolor: (theme: any) => selectedAnswer === null ? alpha(theme.palette.action.disabled, 0.12) : theme.palette.primary.main,
            color: (theme: any) => selectedAnswer === null ? theme.palette.action.disabled : theme.palette.primary.contrastText,
            opacity: selectedAnswer === null ? 0.7 : 1,
            '&:hover': {
              bgcolor: (theme: any) => selectedAnswer === null ? alpha(theme.palette.action.disabled, 0.12) : theme.palette.primary.dark,
            }
          }}
        >
          Siguiente
        </Button>
      </Box>
    </Stack>
  );
};

export default Question;
