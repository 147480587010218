import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { getAuth, User as firebaseUser } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getOrCreateUserData } from "../api/user";
import { User } from "../types/dbTypes";

declare var MercadoPago: any;

const mp = new MercadoPago("APP_USR-a4049256-b95a-4fbb-a7fc-12a94992c3b6", {
  locale: "es-AR",
});

const features = [
  "Acceso ilimitado a todos los algoritmos",
  "Algoritmo de BGN Edición 2024",
  "Algoritmo de BGC Edición 2023",
  "Soporte prioritario",
  "Acceso temprano a nuevos algoritmos",
];

export default function MPCheckout() {
  const [user, setUser] = React.useState<firebaseUser | null>(null);
  const [userDoc, setUserDoc] = React.useState<User | null>(null);

  const navigate = useNavigate();
  React.useEffect(() => {
    getAuth().onAuthStateChanged((user) => {
      if (user) setUser(user);
      else navigate("/login");
    });
  });

  React.useEffect(() => {
    if (user) {
      getOrCreateUserData(user.uid).then((userDoc) => {
        setUserDoc(userDoc);
      });
    }
  }, [user]);

  const handleCheckout = async () => {
    if (!user || !user.email) return;

    try {
      const order = await axios.post(
        "https://algoritmos.invera.org/api/mercadopago",
        { email: user.email, userId: user.uid }
      );
      const preferenceId = order.data.preferenceId;

      mp.checkout({
        preference: { id: preferenceId },
        render: {
          container: "#mp-container",
          label: "Pagar",
        },
        mode: "popup",
        autoOpen: true,
      });
    } catch (error) {
      console.error("Error al iniciar el checkout:", error);
    }
  };

  if (!user || !userDoc) return null;

  return (
    <Box sx={{ display: 'flex' }}>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          overflow: 'auto',
        }}
      >
        <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
          <Paper
            variant="outlined"
            sx={{
              p: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 3,
            }}
          >
            <Typography variant="h4" align="center" gutterBottom>
              Suscripción Premium
            </Typography>

            <Typography variant="h6" color="primary" gutterBottom>
              $5000 ARS, único pago hasta actualizaciones
            </Typography>

            <Typography variant="body1" color="text.secondary" align="center" sx={{ maxWidth: 600 }}>
              Obtén acceso ilimitado a todos nuestros algoritmos y características premium.
              Mejora tu experiencia con las últimas actualizaciones y funcionalidades.
            </Typography>

            <List sx={{ width: '100%', maxWidth: 500 }}>
              {features.map((feature, index) => (
                <ListItem key={index}>
                  <ListItemIcon>
                    <CheckCircleIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary={feature}
                    primaryTypographyProps={{
                      fontWeight: 500,
                    }}
                  />
                </ListItem>
              ))}
            </List>

            <div id="mp-container" style={{ display: 'none' }}></div>
            <Button
              variant="contained"
              size="large"
              onClick={handleCheckout}
              sx={{
                py: 1.5,
                px: 4,
                mt: 2,
              }}
            >
              Comprar Premium
            </Button>
          </Paper>
          <Typography variant="body1" color="text.secondary" align="center" sx={{ mt: 2 }}>
            En caso de haber pagado y que no haya impactado en tu cuenta, por favor contactarse directamente a ulilopezpacho@gmail.com
          </Typography>
        </Container>
      </Box>

    </Box>
  );
}
