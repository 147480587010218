import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import MuiCard from '@mui/material/Card';
import Alert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from "react-router-dom";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import InveraLogo from "../components/InveraLogo";

const Card = styled(MuiCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  width: '100%',
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  margin: 'auto',
  [theme.breakpoints.up('sm')]: {
    maxWidth: '450px',
  },
  boxShadow:
    'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
  ...theme.applyStyles('dark', {
    boxShadow:
      'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px',
  }),
}));

const RecoverContainer = styled(Stack)(({ theme }) => ({
  minHeight: '100vh',
  padding: theme.spacing(2),
  position: 'relative',
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(4),
  },
  '&::before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    zIndex: -1,
    inset: 0,
    backgroundImage:
      'radial-gradient(ellipse at 50% 50%, hsl(150, 100%, 97%), hsl(0, 0%, 100%))',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    ...theme.applyStyles('dark', {
      backgroundImage:
        'radial-gradient(ellipse at 50% 50%, hsla(150, 30%, 16%, 0.5), hsl(220, 30%, 5%))',
    }),
  },
}));

export default function Recover() {
  const [email, setEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [isSuccess, setIsSuccess] = React.useState(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setEmailError("");

    if (!email) {
      setEmailError("Por favor ingresa tu email");
      return;
    }

    try {
      const auth = getAuth();
      await sendPasswordResetEmail(auth, email);
      setIsSuccess(true);
    } catch (error: any) {
      if (error.code === 'auth/user-not-found') {
        setEmailError("No existe una cuenta con este email");
      } else {
        setEmailError("Ocurrió un error al enviar el email de recuperación");
      }
    }
  };

  return (
    <>
      <CssBaseline enableColorScheme />
      <RecoverContainer direction="column" justifyContent="space-between">
        <Card variant="outlined">
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 1 }}>
            <InveraLogo width='120px' />
          </Box>
          <Typography
            component="h1"
            variant="h4"
            sx={{
              width: '100%',
              fontSize: 'clamp(2rem, 10vw, 2.15rem)',
              fontWeight: 500,
              mb: 3
            }}
          >
            Recuperar Contraseña
          </Typography>
          {isSuccess ? (
            <Box sx={{ width: '100%', textAlign: 'center' }}>
              <Alert severity="success" sx={{ mb: 3 }}>
                Hemos enviado un email con las instrucciones para recuperar tu contraseña
              </Alert>
              <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
                Por favor revisa tu bandeja de entrada y sigue las instrucciones en el email.
              </Typography>
              <Button
                component={RouterLink}
                to="/login"
                fullWidth
                variant="contained"
                sx={{ py: 1.5 }}
              >
                Volver al inicio de sesión
              </Button>
            </Box>
          ) : (
            <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                gap: 2,
              }}
            >
              <FormControl>
                <FormLabel htmlFor="email">Email</FormLabel>
                <TextField
                  error={!!emailError}
                  helperText={emailError}
                  id="email"
                  type="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="tu@email.com"
                  autoComplete="email"
                  autoFocus
                  required
                  fullWidth
                  variant="outlined"
                />
              </FormControl>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ py: 1.5 }}
              >
                Enviar Instrucciones
              </Button>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Link
                  component={RouterLink}
                  to="/login"
                  variant="body2"
                >
                  Volver al inicio de sesión
                </Link>
              </Box>
            </Box>
          )}
        </Card>
        <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 4 }}>
          Te enviaremos un correo con las instrucciones para recuperar tu contraseña
        </Typography>
      </RecoverContainer>
    </>
  );
}
