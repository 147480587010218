import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import MuiCard from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import { GoogleAuthProvider, getAuth, signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import * as React from 'react';
import { Link as RouterLink, useNavigate } from "react-router-dom";
import InveraLogo from "../components/InveraLogo";
import { GoogleIcon } from '../components/CustomIcons';

const Card = styled(MuiCard)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignSelf: 'center',
	width: '100%',
	padding: theme.spacing(4),
	gap: theme.spacing(2),
	margin: 'auto',
	[theme.breakpoints.up('sm')]: {
		maxWidth: '450px',
	},
	boxShadow:
		'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
	...theme.applyStyles('dark', {
		boxShadow:
			'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px',
	}),
}));

const SignInContainer = styled(Stack)(({ theme }) => ({
	minHeight: '100vh',
	padding: theme.spacing(2),
	position: 'relative',
	[theme.breakpoints.up('sm')]: {
		padding: theme.spacing(4),
	},
	'&::before': {
		content: '""',
		display: 'block',
		position: 'absolute',
		zIndex: -1,
		inset: 0,
		backgroundImage:
			'radial-gradient(ellipse at 50% 50%, hsl(150, 100%, 97%), hsl(0, 0%, 100%))',
		backgroundRepeat: 'no-repeat',
		backgroundAttachment: 'fixed',
		...theme.applyStyles('dark', {
			backgroundImage:
				'radial-gradient(ellipse at 50% 50%, hsla(150, 30%, 16%, 0.5), hsl(220, 30%, 5%))',
		}),
	},
}));

function Copyright(props: any) {
	return (
		<Typography variant="body2" color="text.secondary" align="center" {...props}>
			Al iniciar sesión, estas aceptando los términos y condiciones de la aplicación
		</Typography>
	);
}

export default function SignIn() {
	const [email, setEmail] = React.useState("");
	const [password, setPassword] = React.useState("");
	const [emailError, setEmailError] = React.useState("");
	const [passwordError, setPasswordError] = React.useState("");
	const navigate = useNavigate();

	React.useEffect(() => {
		getAuth().onAuthStateChanged((next) => {
			if (next != null) {
				navigate("/");
			}
		});
	});

	const validateInputs = () => {
		let isValid = true;

		if (!email || !/\S+@\S+\.\S+/.test(email)) {
			setEmailError("Por favor ingrese un email válido");
			isValid = false;
		} else {
			setEmailError("");
		}

		if (!password || password.length < 6) {
			setPasswordError("La contraseña debe tener al menos 6 caracteres");
			isValid = false;
		} else {
			setPasswordError("");
		}

		return isValid;
	};

	const signIn = () => {
		if (!validateInputs()) return;

		const auth = getAuth();
		signInWithEmailAndPassword(auth, email, password).catch((e) => {
			const errorCode: string = e.code;

			if (errorCode === "auth/invalid-email") {
				setEmailError("Email inválido");
			} else if (errorCode === "auth/wrong-password") {
				setPasswordError("Contraseña incorrecta");
			} else {
				setPasswordError(`Error desconocido: ${errorCode}`);
			}
		});
	};

	const googleSignIn = () => {
		const auth = getAuth();
		const provider = new GoogleAuthProvider();
		signInWithPopup(auth, provider).catch((e) => {
			setPasswordError(`Error desconocido: ${e.code}`);
		});
	};

	return (
		<>
			<CssBaseline enableColorScheme />
			<SignInContainer direction="column" justifyContent="space-between">
				<Card variant="outlined">
					<Box sx={{ display: 'flex', justifyContent: 'center', mb: 1 }}>
						<InveraLogo width='120px' />
					</Box>
					<Typography
						component="h1"
						variant="h4"
						sx={{
							width: '100%',
							fontSize: 'clamp(2rem, 10vw, 2.15rem)',
							fontWeight: 500,
							mb: 3
						}}
					>
						Iniciar Sesión
					</Typography>
					<Box
						component="form"
						sx={{
							display: 'flex',
							flexDirection: 'column',
							width: '100%',
							gap: 2,
						}}
					>
						<FormControl>
							<FormLabel htmlFor="email">Email</FormLabel>
							<TextField
								error={emailError.length > 0}
								helperText={emailError}
								id="email"
								type="email"
								name="email"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								placeholder="tu@email.com"
								autoComplete="email"
								autoFocus
								required
								fullWidth
								variant="outlined"
							/>
						</FormControl>
						<FormControl>
							<FormLabel htmlFor="password">Contraseña</FormLabel>
							<TextField
								error={passwordError.length > 0}
								helperText={passwordError}
								name="password"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
								placeholder="••••••"
								type="password"
								id="password"
								autoComplete="current-password"
								required
								fullWidth
								variant="outlined"
							/>
						</FormControl>
						<Button
							fullWidth
							variant="contained"
							onClick={signIn}
							sx={{ py: 1.5 }}
						>
							Iniciar Sesión
						</Button>
						<Box sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
							<Link
								component={RouterLink}
								to="/recover"
								variant="body2"
							>
								¿Olvidaste tu contraseña?
							</Link>
							<Typography variant="body2" color="text.secondary">•</Typography>
							<Link
								component={RouterLink}
								to="/terms"
								variant="body2"
								target="_blank"
							>
								Términos y condiciones
							</Link>
						</Box>
					</Box>
					<Divider sx={{ my: 2 }}>o</Divider>
					<Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
						<Button
							fullWidth
							variant="outlined"
							onClick={googleSignIn}
							startIcon={<GoogleIcon />}
							sx={{
								py: 1.5,
								borderColor: 'divider',
								'&:hover': {
									borderColor: 'primary.main',
									bgcolor: 'action.hover',
								},
							}}
						>
							Iniciar sesión con Google
						</Button>
						<Typography sx={{ textAlign: 'center' }}>
							¿No tienes una cuenta?{' '}
							<Link
								component={RouterLink}
								to="/register"
								variant="body2"
							>
								Regístrate
							</Link>
						</Typography>
					</Box>
				</Card>
				<Copyright sx={{ mt: 4, textAlign: 'center' }} />
			</SignInContainer>
		</>
	);
}