import { Paper, Stack, Typography, Container, Box, Divider } from "@mui/material";
import React from "react";

function Terms() {
  return (
    <Container maxWidth="md" sx={{ py: 8 }}>
      <Paper
        elevation={0}
        variant="outlined"
        sx={{
          p: { xs: 3, md: 6 },
          borderRadius: 2,
        }}
      >
        <Typography
          variant="h4"
          component="h1"
          align="center"
          sx={{
            mb: 4,
            fontWeight: 600,
            color: 'text.primary'
          }}
        >
          Términos y Condiciones
        </Typography>

        <Divider sx={{ mb: 4 }} />

        <Stack spacing={3}>
          <Box>
            <Typography
              variant="h6"
              component="h2"
              sx={{
                mb: 2,
                color: 'text.primary',
                fontWeight: 500
              }}
            >
              1. Uso de la Aplicación
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: 'text.secondary',
                lineHeight: 1.7
              }}
            >
              Los algoritmos proporcionados en esta aplicación sugieren esquemas empíricos iniciales basados en la evidencia científica publicada hasta junio 2024. Es importante entender que esta herramienta:
            </Typography>
            <Box component="ul" sx={{ mt: 2, color: 'text.secondary' }}>
              <Typography component="li" sx={{ mb: 1 }}>
                No reemplaza el juicio clínico profesional
              </Typography>
              <Typography component="li" sx={{ mb: 1 }}>
                No sustituye los datos de epidemiología local
              </Typography>
              <Typography component="li">
                Debe ser utilizada como una guía de referencia, no como una fuente definitiva de decisiones médicas
              </Typography>
            </Box>
          </Box>

          <Box>
            <Typography
              variant="h6"
              component="h2"
              sx={{
                mb: 2,
                color: 'text.primary',
                fontWeight: 500
              }}
            >
              2. Limitación de Responsabilidad
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: 'text.secondary',
                lineHeight: 1.7
              }}
            >
              Los autores y proveedores de esta aplicación no se responsabilizan de la decisión final de tratamiento antimicrobiano guiado por la Web App. Cada profesional de la salud debe ejercer su propio criterio al utilizar la información proporcionada.
            </Typography>
          </Box>

          <Box>
            <Typography
              variant="h6"
              component="h2"
              sx={{
                mb: 2,
                color: 'text.primary',
                fontWeight: 500
              }}
            >
              3. Privacidad y Protección de Datos
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: 'text.secondary',
                lineHeight: 1.7
              }}
            >
              Nos comprometemos a proteger la privacidad de nuestros usuarios. La información recopilada se utiliza únicamente para mejorar la experiencia del usuario y el funcionamiento de la aplicación.
            </Typography>
          </Box>
        </Stack>
      </Paper>
    </Container>
  );
}

export default Terms;
