import * as React from 'react';
import Box from '@mui/material/Box';
import { Outlet, useLocation } from 'react-router-dom';
import SideMenu from '../components/SideMenu';
import Header from '../components/Header';
import { getAuth, User as firebaseUser } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { getOrCreateUserData } from "../api/user";
import { User } from "../types/dbTypes";

export default function MainLayout() {
    const [user, setUser] = React.useState<firebaseUser | null>(null);
    const [userDoc, setUserDoc] = React.useState<User | null>(null);
    const navigate = useNavigate();
    const location = useLocation();

    React.useEffect(() => {
        getAuth().onAuthStateChanged((user) => {
            if (user) setUser(user);
            else navigate("/login");
        });
    });

    React.useEffect(() => {
        if (user) {
            getOrCreateUserData(user.uid).then((userDoc) => {
                setUserDoc(userDoc);
            });
        }
    }, [user]);

    if (!user || !userDoc) return null;

    const showHeader = !location.pathname.includes('/assistant');

    return (
        <Box sx={{ display: 'flex' }}>
            <SideMenu />
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    overflow: 'auto',
                }}
            >
                {showHeader && (
                    <Header username={user?.displayName || user?.email || ""} />
                )}
                <Outlet />
            </Box>
        </Box>
    );
} 