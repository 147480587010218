import React from 'react'
import { Box } from "@mui/material";

interface Props {
  height?: string;
  width?: string;
}

const InveraLogo: React.FC<Props> = (props) => {
  return (
    <Box
      component="a"
      href="https://invera.org/"
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: props.height,
        width: props.width,
      }}
    >
      <img
        src="invera-logo.png"
        alt="Logo de Invera"
        style={{
          maxWidth: '100%',
          maxHeight: '100%',
          objectFit: 'contain',
        }}
      />
    </Box>
  );
};

export default InveraLogo;
