import AddIcon from '@mui/icons-material/Add';
import HistoryIcon from '@mui/icons-material/History';
import PersonIcon from '@mui/icons-material/Person';
import StarIcon from '@mui/icons-material/Star';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { User as firebaseUser, getAuth } from "firebase/auth";
import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { getOrCreateUserData } from "../api/user";
import { MAX_FREE_ALGORITHMS } from '../globals';
import { User } from "../types/dbTypes";

export default function Home() {
  const [user, setUser] = React.useState<firebaseUser | null>(null);
  const [userDoc, setUserDoc] = React.useState<User | null>(null);

  const navigate = useNavigate();
  React.useEffect(() => {
    getAuth().onAuthStateChanged((user) => {
      if (user) setUser(user);
      else navigate("/login");
    });
  });

  React.useEffect(() => {
    if (user) {
      getOrCreateUserData(user.uid).then((userDoc) => {
        setUserDoc(userDoc);
      });
    }
  }, [user]);

  if (!user || !userDoc) return null;

  const QuickAccessButton = ({ icon, label, onClick }: { icon: React.ReactNode, label: string, onClick: () => void }) => (
    <Button
      variant="outlined"
      onClick={onClick}
      sx={{
        p: 3,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        width: '100%',
        height: '100%',
        borderColor: 'divider',
        '&:hover': {
          borderColor: 'primary.main',
          bgcolor: 'action.hover',
        },
      }}
    >
      {icon}
      <Typography variant="h6" component="span">
        {label}
      </Typography>
    </Button>
  );

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        {userDoc.userType === "Free" && userDoc.answers > MAX_FREE_ALGORITHMS ? (
          <Grid item xs={12}>
            <Paper
              variant="outlined"
              sx={{
                p: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 3,
                textAlign: 'center',
                mb: 3,
              }}
            >
              <StarIcon sx={{ fontSize: 60, color: 'primary.main' }} />
              <Typography variant="h5">
                Actualiza a Premium
              </Typography>
              <Typography variant="body1" color="text.secondary" sx={{ maxWidth: 600 }}>
                Obtén acceso ilimitado a todos nuestros algoritmos y características.
                Mejora tu experiencia con una suscripción premium.
              </Typography>
              <Button
                variant="contained"
                size="large"
                onClick={() => navigate('/checkout')}
                sx={{
                  mt: 2,
                  px: 4,
                  py: 1.5,
                }}
              >
                Obtener Premium
              </Button>
            </Paper>
          </Grid>
        ) : null}
        <Grid item xs={12} sm={6} md={4}>
          <QuickAccessButton
            icon={<AddIcon sx={{ fontSize: 40 }} />}
            label="Nuevo Algoritmo"
            onClick={() => navigate('/new')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <QuickAccessButton
            icon={<HistoryIcon sx={{ fontSize: 40 }} />}
            label="Historial"
            onClick={() => navigate('/history')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <QuickAccessButton
            icon={<PersonIcon sx={{ fontSize: 40 }} />}
            label="Perfil"
            onClick={() => navigate('/profile')}
          />
        </Grid>
      </Grid>
    </Container>
  );
}
