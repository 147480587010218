import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import HistoryIcon from '@mui/icons-material/History';
import AddIcon from '@mui/icons-material/Add';
import StarIcon from '@mui/icons-material/Star';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import SettingsBrightnessIcon from '@mui/icons-material/SettingsBrightness';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useNavigate, useLocation } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { getOrCreateUserData } from "../api/user";
import { useColorScheme } from '@mui/material/styles';

const drawerWidth = 240;

export default function SideMenu() {
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [userType, setUserType] = React.useState<string>("Free");
    const navigate = useNavigate();
    const location = useLocation();
    const { mode, setMode } = useColorScheme();

    React.useEffect(() => {
        const auth = getAuth();
        auth.onAuthStateChanged((user) => {
            if (user) {
                getOrCreateUserData(user.uid).then((userDoc) => {
                    setUserType(userDoc.userType);
                });
            }
        });
    }, []);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleLogout = () => {
        getAuth().signOut();
    };

    const handleThemeChange = (event: any) => {
        setMode(event.target.value);
    };

    const isCurrentPath = (path: string) => {
        if (path === '/new' && location.pathname.includes('/assistant')) {
            return true;
        }
        return location.pathname === path;
    };

    const drawer = (
        <Box sx={{ width: drawerWidth }}>
            <List>
                <ListItem>
                    <ListItemButton
                        onClick={() => navigate('/')}
                        selected={isCurrentPath('/')}
                        sx={{
                            '&.Mui-selected': {
                                bgcolor: 'action.selected',
                                '&:hover': {
                                    bgcolor: 'action.selected',
                                },
                            },
                        }}
                    >
                        <ListItemIcon>
                            <HomeIcon color={isCurrentPath('/') ? 'primary' : 'inherit'} />
                        </ListItemIcon>
                        <ListItemText
                            primary="Inicio"
                            primaryTypographyProps={{
                                color: isCurrentPath('/') ? 'primary' : 'inherit',
                                fontWeight: isCurrentPath('/') ? 500 : 400,
                            }}
                        />
                    </ListItemButton>
                </ListItem>
                <ListItem>
                    <ListItemButton
                        onClick={() => navigate('/new')}
                        selected={isCurrentPath('/new')}
                        sx={{
                            '&.Mui-selected': {
                                bgcolor: 'action.selected',
                                '&:hover': {
                                    bgcolor: 'action.selected',
                                },
                            },
                        }}
                    >
                        <ListItemIcon>
                            <AddIcon color={isCurrentPath('/new') ? 'primary' : 'inherit'} />
                        </ListItemIcon>
                        <ListItemText
                            primary="Nuevo Algoritmo"
                            primaryTypographyProps={{
                                color: isCurrentPath('/new') ? 'primary' : 'inherit',
                                fontWeight: isCurrentPath('/new') ? 500 : 400,
                            }}
                        />
                    </ListItemButton>
                </ListItem>
                <ListItem>
                    <ListItemButton
                        onClick={() => navigate('/history')}
                        selected={isCurrentPath('/history')}
                        sx={{
                            '&.Mui-selected': {
                                bgcolor: 'action.selected',
                                '&:hover': {
                                    bgcolor: 'action.selected',
                                },
                            },
                        }}
                    >
                        <ListItemIcon>
                            <HistoryIcon color={isCurrentPath('/history') ? 'primary' : 'inherit'} />
                        </ListItemIcon>
                        <ListItemText
                            primary="Historial"
                            primaryTypographyProps={{
                                color: isCurrentPath('/history') ? 'primary' : 'inherit',
                                fontWeight: isCurrentPath('/history') ? 500 : 400,
                            }}
                        />
                    </ListItemButton>
                </ListItem>
                <ListItem>
                    <ListItemButton
                        onClick={() => navigate('/profile')}
                        selected={isCurrentPath('/profile')}
                        sx={{
                            '&.Mui-selected': {
                                bgcolor: 'action.selected',
                                '&:hover': {
                                    bgcolor: 'action.selected',
                                },
                            },
                        }}
                    >
                        <ListItemIcon>
                            <AccountCircleIcon color={isCurrentPath('/profile') ? 'primary' : 'inherit'} />
                        </ListItemIcon>
                        <ListItemText
                            primary="Perfil"
                            primaryTypographyProps={{
                                color: isCurrentPath('/profile') ? 'primary' : 'inherit',
                                fontWeight: isCurrentPath('/profile') ? 500 : 400,
                            }}
                        />
                    </ListItemButton>
                </ListItem>
                {userType === "Free" && (
                    <ListItem>
                        <ListItemButton
                            onClick={() => navigate('/checkout')}
                            selected={isCurrentPath('/checkout')}
                            sx={{
                                bgcolor: isCurrentPath('/checkout') ? 'primary.dark' : 'primary.main',
                                color: 'primary.contrastText',
                                '&:hover': {
                                    bgcolor: 'primary.dark',
                                },
                                '&.Mui-selected': {
                                    bgcolor: 'primary.dark',
                                    '&:hover': {
                                        bgcolor: 'primary.dark',
                                    },
                                },
                            }}
                        >
                            <ListItemIcon>
                                <StarIcon sx={{ color: 'primary.contrastText' }} />
                            </ListItemIcon>
                            <ListItemText primary="Obtener Premium" />
                        </ListItemButton>
                    </ListItem>
                )}
            </List>
            <Divider />
            <List>
                <ListItem>
                    <FormControl fullWidth variant="outlined" size="small">
                        <Select
                            value={mode}
                            onChange={handleThemeChange}
                            startAdornment={
                                <Box sx={{ mr: 1, display: 'flex', alignItems: 'center' }}>
                                    {mode === 'light' && <LightModeIcon />}
                                    {mode === 'dark' && <DarkModeIcon />}
                                    {mode === 'system' && <SettingsBrightnessIcon />}
                                </Box>
                            }
                        >
                            <MenuItem value="light">Modo Claro</MenuItem>
                            <MenuItem value="dark">Modo Oscuro</MenuItem>
                            <MenuItem value="system">Usar tema del sistema</MenuItem>
                        </Select>
                    </FormControl>
                </ListItem>
                <ListItem>
                    <ListItemButton onClick={handleLogout}>
                        <ListItemIcon>
                            <LogoutIcon />
                        </ListItemIcon>
                        <ListItemText primary="Cerrar Sesión" />
                    </ListItemButton>
                </ListItem>
            </List>
        </Box>
    );

    return (
        <Box>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                sx={{
                    position: 'fixed',
                    left: { xs: 16, sm: 24 },
                    top: 16,
                    zIndex: 1100,
                }}
            >
                <MenuIcon />
            </IconButton>
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
            >
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: drawerWidth,
                        },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: drawerWidth,
                        },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
        </Box>
    );
} 