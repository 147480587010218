import React from "react";
import { Result } from "../types/assistantTypes";
import {
    Stack,
    Typography,
    Button,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    Box,
    alpha,
    Divider
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

interface ResultProps {
    result: Result | undefined;
}

export default function ResultComponent({ result }: ResultProps): JSX.Element {
    const navigate = useNavigate();

    if (!result) {
        return (
            <Stack
                spacing={2}
                alignItems="center"
                sx={{
                    maxWidth: 800,
                    mx: 'auto',
                    width: '100%',
                    py: 4,
                    px: { xs: 2, sm: 4 }
                }}
            >
                <Typography variant="h5" align="center" color="error">
                    Error: No se encontró un resultado
                </Typography>
                <Button
                    variant="contained"
                    onClick={() => navigate("/new")}
                    startIcon={<ArrowForwardIcon />}
                >
                    Volver a los algoritmos
                </Button>
            </Stack>
        );
    }

    return (
        <Stack
            spacing={4}
            sx={{
                maxWidth: 800,
                mx: 'auto',
                width: '100%',
                py: 4,
                px: { xs: 2, sm: 4 },
                mt: { xs: '56px', sm: 0 }
            }}
        >
            <Box sx={{ textAlign: 'center' }}>
                <Box
                    sx={{
                        display: 'inline-flex',
                        p: 2,
                        borderRadius: '50%',
                        bgcolor: (theme) => alpha(theme.palette.success.main, 0.1),
                        color: 'success.main',
                        mb: 2
                    }}
                >
                    <CheckCircleOutlineIcon sx={{ fontSize: 48 }} />
                </Box>
                <Typography
                    variant="h4"
                    gutterBottom
                    sx={{
                        fontWeight: 700,
                        color: 'text.primary'
                    }}
                >
                    Este es el resultado
                </Typography>
                <Typography
                    variant="h5"
                    color="text.secondary"
                    sx={{ mb: 4, fontWeight: 500 }}
                >
                    {result.title}
                </Typography>
            </Box>

            {
                result.bulletPoints.length === 0 || (result.bulletPoints.length === 1 && result.bulletPoints[0] === "") ? (
                    <></>
                ) :
                    <>
                        <Paper
                            variant="outlined"
                            sx={{
                                p: 3,
                                bgcolor: (theme) => alpha(theme.palette.background.paper, 0.6)
                            }}
                        >
                            <Typography
                                variant="h6"
                                gutterBottom
                                sx={{ mb: 2, fontWeight: 600 }}
                            >
                                Recomendaciones:
                            </Typography>
                            <List>
                                {result.bulletPoints.map((bulletPoint, index) => (
                                    <ListItem key={index} alignItems="flex-start">
                                        <ListItemIcon sx={{ mt: 0.5 }}>
                                            <CheckCircleOutlineIcon color="success" />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={bulletPoint}
                                            primaryTypographyProps={{
                                                color: 'text.primary',
                                                sx: { fontWeight: 500 }
                                            }}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </Paper>
                        <Divider />
                    </>
            }


            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                    variant="contained"
                    size="large"
                    onClick={() => navigate("/new")}
                    startIcon={<ArrowForwardIcon />}
                    sx={{
                        minWidth: 250,
                        py: 1.5,
                        fontSize: '1.1rem',
                        fontWeight: 600,
                        borderRadius: 3,
                        bgcolor: (theme) => theme.palette.primary.main,
                        '&:hover': {
                            bgcolor: (theme) => theme.palette.primary.dark,
                        }
                    }}
                >
                    Nuevo Algoritmo
                </Button>
            </Box>
        </Stack>
    );
}