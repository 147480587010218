import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AssistantState, Node } from "../../types/assistantTypes";
import { treeMaxDepth } from "../../utils/treeDfs";

const initialState: AssistantState = {
  currentNode: 0,
  questionTree: [
    {
      id: 0,
      isEnd: true,
      answers: [],
    },
  ],
  maxDepth: 0,
  maxStepsLeft: 0,
  nodeHistory: [],
};

export const counterSlice = createSlice({
  name: "assistantTree",
  initialState,
  reducers: {
    navigateToNode: (state, action: PayloadAction<number>) => {
      state.nodeHistory.push(state.currentNode);
      state.currentNode = action.payload;
      state.maxStepsLeft = treeMaxDepth(state.questionTree, action.payload);
    },
    undoLastNode: (state) => {
      if (state.nodeHistory.length > 0) {
        const previousNode = state.nodeHistory.pop();
        if (previousNode !== undefined) {
          state.currentNode = previousNode;
          state.maxStepsLeft = treeMaxDepth(state.questionTree, previousNode);
        }
      }
    },
    loadTree: (state, action: PayloadAction<Node[]>) => {
      state.questionTree = action.payload;
      state.maxDepth = state.maxStepsLeft = treeMaxDepth(action.payload, 0);
      state.currentNode = 0;
      state.nodeHistory = [];
    },
  },
});

export const { navigateToNode, loadTree, undoLastNode } = counterSlice.actions;

export default counterSlice.reducer;
