import * as React from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { getOrCreateUserData } from "../api/user";
import RecentAlgorithmTable from '../components/RecentAlgorithmTable';

export default function History() {
    const [userId, setUserId] = React.useState<string | null>(null);
    const [answers, setAnswers] = React.useState<number>(0);
    const navigate = useNavigate();

    React.useEffect(() => {
        const auth = getAuth();
        auth.onAuthStateChanged((user) => {
            if (user) {
                setUserId(user.uid);
                getOrCreateUserData(user.uid).then((userDoc) => {
                    setAnswers(userDoc.answers);
                });
            } else {
                navigate("/login");
            }
        });
    }, [navigate]);

    if (!userId) return null;

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Paper
                variant="outlined"
                sx={{
                    p: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                }}
            >
                <Typography variant="h5" sx={{ mb: 2 }}>
                    Historial de Algoritmos
                </Typography>
                {answers > 0 ? (
                    <RecentAlgorithmTable
                        uid={userId}
                        algorithmAmount={answers}
                    />
                ) : (
                    <Typography variant="body1" color="text.secondary" align="center">
                        Aún no has completado ningún algoritmo
                    </Typography>
                )}
            </Paper>
        </Container>
    );
} 